export class PromiseUtils {
    public static async run<T>(task: () => Promise<T>, suppressError = false) {
        try {
            return await task();
        } catch (e) {
            if (!suppressError) {
                throw e;
            }
        }
    }
}
