import { injectable } from 'inversify';

import Toast from '../components/ui/toast/toast.component';

interface ToastOptions {
    disableTimeOut?: boolean;
    timeout?: number;
    closeButton?: boolean;
    tapToDismiss?: boolean;
}

@injectable()
export class ToastService {
    public success(title: string, message: string, options: ToastOptions = {}) {
        Toast.showToast({ title: title, message: message, type: 'success' });
    }

    public warning(title: string, message: string, options: ToastOptions = {}) {
        Toast.showToast({ title: title, message: message, type: 'warning' });
    }

    public error(title: string, message: string, options: ToastOptions = {}) {
        Toast.showToast({ title: title, message: message, type: 'danger' });
    }

}