import { ReplaySubject } from 'rxjs';

import { isBoolean, isEmpty, isEqual } from '../utils/lodash-min';

export type ValidatorFn = (controlValue: string) => {
    [errorName: string]: string; // Error message
}

export interface Validators {
    [validatorName: string]: ValidatorFn
}

export class BasicValidators {
    static required: ValidatorFn = (value) => {
        if (!isBoolean(value) && isEmpty(value)) {
            return {
                required: 'Value required',
            }
        }
        return null;
    }

    static requiredTruthy: ValidatorFn = (value) => {
        if ((!isBoolean(value) && isEmpty(value)) || (isBoolean(value) && !value)) {
            return {
                required: 'Value required',
            }
        }
        return null;
    }
}

export interface ValueSetOptions {
    noEmit?: boolean;
    /**Internal use only */
    noEmitToParent?: boolean;
}

export class FormControl {
    private parent: FormGroup;
    private _value: any;
    private _validators: {
        [validatorName: string]: ValidatorFn
    }

    private _valueChanges = new ReplaySubject<any>(1);
    public valueChanges = this._valueChanges.asObservable();

    constructor(initialValue?: any, validators?: Validators) {
        this._value = initialValue;
        this._validators = validators || {};
    }

    get value() {
        return this._value;
    }

    setValue(value: any, options: ValueSetOptions = {}) {
        const hasChanged = !isEqual(this.value, value);
        this._value = value;
        if (hasChanged) {
            if (!options.noEmit) {
                this._valueChanges.next(this.value);
            }
            if (this.parent && !options.noEmitToParent) {
                this.parent.childValueChanged();
            }
        }
    }

    setValidators(validators?: Validators) {
        this._validators = validators || {};
    }

    get errors() {
        const _errors = Object.keys(this._validators).map(validatorName => {
            const validatorFn = this._validators[validatorName];
            return validatorFn(this.value);
        });
        const errorObj = {};
        _errors.forEach(err => {
            if (err) {
                Object.keys(err).forEach(errKey => {
                    errorObj[errKey] = err[errKey];
                })
            }
        });
        return errorObj;
    }
    get valid() {
        return Object.keys(this.errors).length === 0;
    }

    setParent(formGroup: FormGroup) {
        this.parent = formGroup;
    }
}

export class FormGroup {
    private group: { [controlName: string]: FormControl } = {};
    public readonly _valueChanges = new ReplaySubject<any>(1);
    public readonly valueChanges = this._valueChanges.asObservable();

    get value(): any {
        const valueObj = {};
        Object.keys(this.group).map(key => {
            valueObj[key] = this.group[key].value
        })
        return valueObj;
    }

    get valid() {
        const validObj = {};
        Object.keys(this.group).map(key => {
            validObj[key] = this.group[key].valid
        })
        return Object.keys(validObj).reduce((acc, v) => {
            return validObj[v] && acc;
        }, true);
    }

    get controls(): { [key: string]: FormControl } {
        return this.group;
    }

    constructor(group?: { [key: string]: FormControl }) {
        this.group = {};
        if (group) {
            Object.keys(group).forEach(controlName => {
                this.setControl(controlName, group[controlName]);
            })
        }
    }

    get(controlName: string): FormControl {
        return this.group[controlName];
    }

    setControl(name: string, control?: FormControl, initialValue?: any, validators?: Validators) {
        if (!this.group[name]) {
            this.group[name] = control || new FormControl(initialValue, validators);
            this.group[name].setParent(this);
        } else {
            console.error('FormControl already exists');
        }
    }

    setValue(value: { [controlName: string]: any }, options: ValueSetOptions = {}) {
        Object.keys(value).forEach(controlName => {
            const formControl = this.group[controlName];
            formControl.setValue(value[controlName], Object.assign({}, options, { noEmitToParent: true }));
        });
        if (!options.noEmit) {
            this._valueChanges.next(this.value);
        }
    }

    patchValue(value: { [controlName: string]: any }, options: ValueSetOptions = {}) {
        this.setValue(value, options);
    }

    childValueChanged() {
        this._valueChanges.next(this.value);
    }
}